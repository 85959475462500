
import React from 'react';
import Image from 'blocks/Image';
import Text from 'blocks/Text';
import Default from 'blocks/Default';

const availableBlocks = {
  'Image': Image,
'Text': Text,
};

const Blocks = ({block, rowIndex}) => {
    const blockName = block.__typename.replace(/.*_([a-z]*)$/gi, '$1');

    return availableBlocks[blockName]
        ?  React.createElement(availableBlocks[blockName], {Blocks, rowIndex, ...block})
        : <Default blockType={blockName}/>;
};

export default Blocks;
